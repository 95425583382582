import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import './styles.scss'

const Assurance = loadable(() => import('/src/components/Basic/Assurance'))
//const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const TrustpilotMicroWidget = loadable(() => import('/src/components/Trustpilot/TrustpilotMicroWidget'))
const TrustpilotReviewsWidget = loadable(() => import('/src/components/Trustpilot/TrustpilotReviewsWidget'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const TrustpilotReviews = ({ backgroundColour }) => {
    const globalQuery = useStaticQuery(
        graphql`
            query {
                component: allWp {
                    nodes {
                        globalBlocks {
                            trustpilotReviews {
                                fieldGroupName
                                trustpilotReviews {
                                    addTitleArea
                                    title
                                    description
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const globalData = globalQuery.component.nodes

    return globalData.map((node, index) => {
        const componentData = node.globalBlocks.trustpilotReviews.trustpilotReviews

        const titleArea = {
            addTitleArea: componentData.addTitleArea,
            titlePosition: 'centered',
            title: componentData.title,
            description: componentData.description
        }

        return (
            <Section
                key={index}
                variant={backgroundColour}
                titleArea={titleArea}
                className={'c-trustpilot-reviews-block'}
            >
                <TrustpilotReviewsWidget />

                <TrustpilotMicroWidget backgroundColour={backgroundColour} />

                <Assurance backgroundColour={backgroundColour} />

                {/* <CtaContainer align="centered">
                        <ButtonLink size="sm" data={{url: 'https://uk.trustpilot.com/review/protectline.co.uk', title: 'See more customer reviews', target: "_blank"}} icon="external" />
                    </CtaContainer> */}
            </Section>
        )
    })
}

TrustpilotReviews.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired
}

TrustpilotReviews.defaultProps = {
    backgroundColour: 'light'
}

export default TrustpilotReviews

export const query = graphql`
    fragment TrustpilotReviewsForPageBuilder on WpPage_Acfpagebuilder_Layouts_TrustpilotReviews {
        fieldGroupName
        backgroundColour
    }
`
